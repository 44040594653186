import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Alert, Autocomplete, Checkbox, Grid, TextField, Typography } from "@mui/material";
import CustomPaper from "components/global/CustomPaper";
import { Birthday, Patient, Pencil } from "components/oldDesignAssets/icons";
import { useConfirmationModal } from "contexts/confirmationModalContext";
import { cloneDeep } from "lodash";
import { Fragment, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { getBusinesses } from "services/ubfService";
import { getMediaUrl } from "../../../services/media";
import { addUser, createRelative, updateUser } from "../../../services/userService";
import { requestStore } from "../../../stores/modalManager";
import { store } from "../../../stores/storeManager";
import ImageViewer from "../../global/imageViewer";
import Loader from "../../global/loader";
import Button from "../button";
import DateTimePicker from "../dateTimePicker";
import Footer from "../footer";
import GenderToggle from "../genderToggle";
import Header from "../header";
import { ArrowLeft, Building, Email, IDCard, Phone, Photo, RoundCheck } from "../icons";
import Input from "../input";
import InputPhone from "../inputPhone";
import Tooltip from "../tooltip";
import AppLinks from "./appLinks";
import CreateRequest from "./createRequest";
import EditRequest from "./editRequest";
import Request from "./request";
import { useSnackBar } from "contexts/snackBarContext";

const requiredField = <Tooltip text="Required Field" />;

export default function CreateUser({
  className = "",
  title = "",
  textAction,
  userData,
  isOwner = false,
  onSuccess = () => {},
  readOnly = false,
  ...props
}) {
  const snackBar = useSnackBar();
  const { open } = useConfirmationModal();
  const { alpha2 } = store.useState(s => s.country);
  const owner = requestStore.useState(s => s.unsavedChanges.owner);

  const [user, setUser] = useState(
    userData
      ? {
          ...userData,
          firstName: userData.name,
          businesses: userData.businesses.map(b => ({ _id: b.ref._id, name: b.ref.name })),
        }
      : {
          email: {},
          gender: "M",
          phoneNumbers: [{}],
          sendSMS: isOwner,
          businesses: [],
        },
  );
  const [verification, setVerification] = useState(user?.verifications?.length > 0 ? user.verifications[0] : {});
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState({});
  const [disabled, setDisabled] = useState(readOnly);

  const { businesses } = useBusinesses(alpha2);

  const phoneNumberLength = { 65: [8], 60: [9, 10], 91: [10] };
  const phoneExtension = user.phoneNumbers[0]?.ext;
  const phoneNumber = user.phoneNumbers[0]?.number;
  const validPhoneNumber = phoneNumberLength[phoneExtension]
    ? RegExp(`^\\d{${phoneNumberLength[phoneExtension].join(",")}}$`).test(phoneNumber)
    : true;
  const lengthRequired = phoneNumberLength[phoneExtension]?.join(" or ");

  const addNewUser = async validatedUser => {
    const { data } = await addUser({ ...validatedUser, email: validatedUser.email?.address, alpha2 });
    return data.user;
  };

  const addNewRelative = async validatedUser => {
    const { data } = await createRelative({
      ...validatedUser,
      email: validatedUser.email?.address,
      userRef: owner._id,
      alpha2,
    });
    const updatedPayer = cloneDeep(owner);
    updatedPayer.relatives.push(data.user);
    requestStore.update(s => {
      s.unsavedChanges.owner = updatedPayer;
    });
    return data.user;
  };

  const updateExistingUser = async validatedUser => {
    const { data } = await updateUser(validatedUser._id, {
      ...validatedUser,
      email: validatedUser.email?.address,
      alpha2,
    });
    return data.user;
  };

  const onClickConfirm = async validatedUser => {
    try {
      setIsLoading(true);

      let updatedUser;
      if (validatedUser._id) updatedUser = await updateExistingUser(validatedUser);
      else if (isOwner) updatedUser = await addNewUser(validatedUser);
      else updatedUser = await addNewRelative(validatedUser);

      onSuccess(updatedUser);
      setDisabled(true);
      showRequest();
    } catch (err) {
      const { phoneNumberExist } = err.data;

      snackBar.pop({
        content: phoneNumberExist ? err.data.message : err.data,
        alertProps: { severity: "error" },
      });
      if (phoneNumberExist) setError(err.data);
    } finally {
      setIsLoading(false);
    }
  };

  const showRequest = () => {
    requestStore.update(s => {
      const previousPage = s.request?.id && s.request?.status !== "draft" ? <EditRequest /> : <CreateRequest />;
      s.componentsToRender = readOnly ? <Request /> : previousPage;
    });
  };

  const onClickBack = e => {
    e.preventDefault();
    showRequest();
  };

  const onClickEdit = () => {
    setDisabled(false);
  };

  const validateUser = () => {
    const hasPhoneNumber = user.phoneNumbers?.length && user.phoneNumbers[0].ext && user.phoneNumbers[0].number;

    const err = {
      firstName: !user.firstName,
      phoneNumber: (isOwner && !user.businesses.length && !hasPhoneNumber) || (hasPhoneNumber && !validPhoneNumber),
    };

    const valid = !err.firstName && !err.phoneNumber;
    if (!valid) {
      setError(err);
      return;
    }

    const validatedUser = {
      ...user,
      phoneNumbers: hasPhoneNumber ? user.phoneNumbers : [],
      verification,
      birthdate: user.birthdate ? new Date(user.birthdate) : undefined,
    };

    const isPhoneNumberChanged =
      `${user.phoneNumbers[0]?.ext}${user.phoneNumbers[0]?.number}` !==
      `${userData?.phoneNumbers[0]?.ext}${userData?.phoneNumbers[0]?.number}`;
    if (hasPhoneNumber && !isPhoneNumberChanged) validatedUser.phoneNumbers = undefined;

    const isVerificationIdChanged = verification?.id !== userData?.verifications?.[0]?.id;
    if (!isVerificationIdChanged) {
      validatedUser.verification = undefined;
      validatedUser.verifications = undefined;
    }

    return validatedUser;
  };

  const onClickActionLink = async () => {
    const validatedUser = validateUser();
    if (validatedUser) {
      const confirmationParams = {
        description: "Please ensure Patient information is correct.",
        onConfirm: () => onClickConfirm(validatedUser),
      };

      if (isOwner) {
        if (userData?._id) confirmationParams.description = "Please ensure User information is correct.";
        else if (user.sendSMS) {
          confirmationParams.description =
            "Please ensure User information is correct. An SMS will be sent to the user to download the App.";
        }
      }

      open(confirmationParams);
    }
  };

  const onClickCheckSendAppLinkSMS = () => {
    setUser({ ...user, sendSMS: !user.sendSMS });
  };

  const onChangeBusinesses = businesses => {
    setUser({ ...user, businesses });
  };

  const onChangeVerification = updatedFields => {
    setVerification({ ...verification, ...updatedFields });
  };

  const phoneNumberInfoText = !user.phoneNumbers[0]?.number || error.phoneNumberExist ? requiredField : "";

  const idPhoto = verification.photo?.storage?.key ? (
    <ImageViewer
      src={getMediaUrl("verification-file", { keyPath: verification.photo?.storage?.key })}
      alt="verification"
    />
  ) : (
    <div className="noPhoto">
      <Photo />
      <div>No ID Uploaded</div>
    </div>
  );

  return (
    <div className={`modalContent createUserUM ${className}`} {...props}>
      <Header>
        <ArrowLeft role="button" aria-label="backButton" onClick={onClickBack} />
        <div className="title">{title}</div>
        {readOnly && disabled && <Pencil sx={{ color: "#979797" }} onClick={onClickEdit} />}
      </Header>
      <div className="content">
        <div className="formContainer">
          <Input
            disabled={disabled || isLoading}
            icon={<Patient />}
            value={user.firstName}
            onChange={firstName => setUser({ ...user, firstName })}
            placeholder="Full Name"
            data-testid="inputGivenName"
            className="firstName"
            showInfo={error.firstName}
            info={requiredField}
          />
          <div className="birthdatePicker">
            <Birthday />
            <DateTimePicker
              selected={user.birthdate}
              onChange={birthdate => setUser({ ...user, birthdate })}
              minDate={null}
              maxDate={new Date()}
              showMonthDropdown
              showYearDropdown
              showTimeSelect={false}
              disabled={disabled}
              placeholderDate="Birthdate"
              dropdownMode="select"
              className="birthdate"
              dateFormat="dd MMM yyyy"
            />
          </div>
          <GenderToggle
            disabled={disabled || isLoading}
            value={user.gender}
            className="gender"
            onChange={gender => setUser({ ...user, gender })}
          />
          <hr className="hr1" />
          {error.phoneNumberExist && (
            <Alert
              severity="error"
              icon={false}
              sx={{
                gridColumn: "span 4",
                padding: "5px 16px",
                margin: "-16px -16px 0px",
                borderRadius: "unset",
                color: "#FF1F4A",
                fontSize: "13px",
                fontWeight: "500",
                ".MuiAlert-message": { padding: "0px" },
              }}>
              {error.message}
            </Alert>
          )}
          <InputPhone
            disabled={disabled || isLoading}
            ext={user.phoneNumbers[0]?.ext}
            number={user.phoneNumbers[0]?.number}
            onChange={phone => setUser({ ...user, phoneNumbers: [phone] })}
            placeholder="Mobile number"
            data-testid="inputMobileNumber"
            className="phone"
            showInfo={error.phoneNumber || error.phoneNumberExist}
            info={!validPhoneNumber ? `The phone number requires ${lengthRequired} digits` : phoneNumberInfoText}
            icon={<Phone />}
          />
          <Input
            disabled={disabled || isLoading}
            value={user.email.address}
            onChange={address => setUser({ ...user, email: { address } })}
            placeholder="Add email"
            data-testid="inputEmail"
            className="email"
            icon={<Email />}
          />

          <hr className="hr2" />

          <div className="verification">
            {readOnly && disabled && user.verifications.length ? (
              user.verifications.map((verificationItem, index) => (
                <Fragment key={`verificationId_${index}`}>
                  <div className="verificationContainer">
                    <div className="grow">
                      <div className="verificationId">
                        <Input
                          className="idType"
                          placeholder="ID Type"
                          value={verificationItem?.type?.toUpperCase()}
                          icon={<IDCard />}
                          disabled={disabled || isLoading}
                        />
                        <Input
                          className="idNumber"
                          placeholder="ID Number"
                          value={verificationItem?.id}
                          disabled={disabled || isLoading}
                        />
                      </div>
                    </div>

                    <div className="idPhoto">
                      {verificationItem.photo?.storage?.key ? (
                        <ImageViewer
                          src={getMediaUrl("verification-file", { keyPath: verificationItem.photo?.storage?.key })}
                          alt="verification"
                        />
                      ) : (
                        <div className="noPhoto">
                          <Photo />
                          <div>No ID Uploaded</div>
                        </div>
                      )}
                    </div>
                  </div>
                </Fragment>
              ))
            ) : (
              <>
                <div className="verificationContainer">
                  <div className="grow">
                    <div className="verificationId">
                      <Input
                        className="idType"
                        placeholder="ID Type"
                        value={verification?.type?.toUpperCase()}
                        icon={<IDCard />}
                        disabled={disabled || isLoading}
                      />
                      <Input
                        className="idNumber"
                        placeholder="ID Number"
                        data-testid="inputIdNumber"
                        value={verification?.id}
                        onChange={id => onChangeVerification({ id })}
                        disabled={disabled || isLoading}
                      />
                    </div>
                  </div>
                  <div className="idPhoto">{idPhoto}</div>
                </div>
              </>
            )}
          </div>

          <hr className="hr3" />

          <Grid container gap={2} alignItems="center" style={{ gridColumn: "span 4" }}>
            <Building />

            <Grid item xs>
              <Autocomplete
                value={user.businesses}
                onChange={(_event, value) => {
                  onChangeBusinesses(value);
                }}
                options={businesses}
                isOptionEqualToValue={(option, newValue) => option._id === newValue._id}
                getOptionLabel={option => option.name}
                renderInput={params => (
                  <TextField
                    {...params}
                    placeholder={user.businesses.length === 0 ? "Add Corporate Account" : ""}
                    size="small"
                  />
                )}
                ChipProps={{
                  size: "small",
                  style: { margin: "1px 4px", fontSize: 12, fontWeight: 500 },
                }}
                popupIcon={<ExpandMoreIcon />}
                renderOption={(props, option, { selected }) => (
                  <li {...props}>
                    <Typography fontSize={12}>{option.name}</Typography>
                    <Checkbox checked={selected} color="secondary" size="small" style={{ marginLeft: "auto" }} />
                  </li>
                )}
                noOptionsText={<Typography fontSize={12}>No results matching your search</Typography>}
                PaperComponent={CustomPaper}
                disabled={disabled}
                fullWidth
                disableCloseOnSelect
                multiple
              />
            </Grid>
          </Grid>

          {isOwner && (
            <>
              <hr className="hr4" />
              <AppLinks user={userData} defaultChecked={user.sendSMS} onClickCheckBox={onClickCheckSendAppLinkSMS} />
            </>
          )}
        </div>
      </div>

      {textAction && (
        <Footer>
          {isLoading ? (
            <Loader />
          ) : !readOnly && disabled ? (
            <Button
              text={userData?._id ? "Updated" : "Created"}
              type="button"
              className="confirm noClick"
              icon={<RoundCheck />}
            />
          ) : !readOnly || (readOnly && !disabled) ? (
            <Link to="#" className="linkGreen" data-testid="createButton" type="button" onClick={onClickActionLink}>
              {textAction}
            </Link>
          ) : null}
        </Footer>
      )}
    </div>
  );
}

function useBusinesses(alpha2) {
  const [businesses, setBusinesses] = useState([]);

  useEffect(() => {
    let active = true;

    const fetchBusinesses = async () => {
      const { data } = await getBusinesses({ alpha2 });

      if (!active) return;

      setBusinesses(data.businesses);
    };

    fetchBusinesses();

    return () => {
      active = false;
    };
  }, [alpha2]);

  return { businesses };
}
