import { Trash, X } from "components/oldDesignAssets/icons";
import { useSnackBar } from "contexts/snackBarContext";
import { useEffect, useMemo, useState } from "react";
import { useModal } from "react-modal-hook";
import { Document, Page, pdfjs } from "react-pdf";
import httpService from "../../services/httpService";
import { deleteMedia } from "../../services/requestService";
import { requestStore } from "../../stores/modalManager";
import { formattedAttachmentName, formattedAttachmentSize } from "../../utils/globalUtils";
import Loader from "../global/loader";
import Modal from "../global/modal";
import { ChevronDown, Download } from "./icons";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

const pdfThumbnail = "//public.speedoc.com/strapi/img_pdf_cd7fa03467.png";

const PdfDocument = ({ media }) => {
  const [page, setPage] = useState(1);
  const [pages, setPages] = useState();
  const file = useMemo(
    () => ({
      url: media.data.url,
      httpHeaders: {
        Authorization: localStorage.getItem("jwt"),
        "x-api-key": localStorage.getItem("key"),
      },
    }),
    [media],
  );
  const goToPage = p => {
    if (p < 1 || p > pages) return;
    setPage(p);
  };

  const onLoadSuccess = props => {
    setPages(props._pdfInfo.numPages);
  };
  return (
    <div>
      <Document
        className="pdfFile"
        loading={
          <div className="pdfLoader">
            <Loader />
          </div>
        }
        onLoadSuccess={onLoadSuccess}
        file={file}>
        <Page pageNumber={page} height={window.innerHeight - 100} />
      </Document>
      {pages && (
        <div className="pdfNavigation">
          <ChevronDown className={`prev ${page <= 1 ? "hidden" : ""}`} onClick={() => goToPage(page - 1)} />
          Page {page} of {pages}
          <ChevronDown className={`next ${page >= pages ? "hidden" : ""}`} onClick={() => goToPage(page + 1)} />
        </div>
      )}
    </div>
  );
};

export default function PdfAttachment({ media, alt = "attachment" }) {
  const snackBar = useSnackBar();
  const request = requestStore.useState(s => ({ ...s.request, ...s.unsavedChanges }));
  const mediaRecord = request.records.find(r => r.type === "media");
  const [isLoading, setIsLoading] = useState(false);
  const [pdfURL, setPdfURL] = useState();

  const [showModal, hideModal] = useModal(() => {
    return (
      <Modal className="previewMediaModal pdf" show={showModal} hide={hideModal} modalClassName="previewModal">
        <div className="content">
          <PdfDocument media={media} />
        </div>
        <span className="cross" onClick={hideModal}>
          <X />
        </span>
      </Modal>
    );
  }, []);

  const removePdf = async (e, media) => {
    setIsLoading(true);
    e.stopPropagation();
    if (media && media.data.url && mediaRecord) {
      try {
        const { data } = await deleteMedia(request._id, { mediaRef: media.data.id, recordRef: mediaRecord._id });
        requestStore.update(s => {
          s.request = data.service;
        });
      } catch (error) {
        snackBar.pop({
          content: error.data?.toString(),
          alertProps: { severity: "error" },
        });
      }
    }
    setIsLoading(false);
  };

  useEffect(() => {
    if (media?.data?.url) {
      const fetchImage = async () => {
        const imageResponse = await httpService.get(media.data.url, { responseType: "blob" });
        setPdfURL(URL.createObjectURL(imageResponse.data));
      };
      fetchImage();
    }
  }, [media]);

  return !isLoading ? (
    <div onClick={showModal} className="mediaGroup pdf">
      <img className="pdfThumbnail" src={pdfThumbnail} alt={alt} />
      <div className="mediaInformation">
        <div className="name">{media.data.fileName ? formattedAttachmentName(media.data.fileName) : ""}</div>
        <div className="size">{media.data.size ? formattedAttachmentSize(media.data.size) : ""}</div>
      </div>
      <div className="hover">
        <div className="mediaInformation">
          <div className="name">{media.data.fileName ? formattedAttachmentName(media.data.fileName) : ""}</div>
          <div className="size">{media.data.size ? formattedAttachmentSize(media.data.size) : ""}</div>
        </div>
        <div className="actions">
          <a href={pdfURL} download={media.data.fileName} onClick={e => e.stopPropagation()}>
            <Download />
          </a>
          <Trash onClick={e => removePdf(e, media)} />
        </div>
      </div>
    </div>
  ) : (
    <Loader />
  );
}
