import { Grid, Link, Tooltip, Typography } from "@mui/material";
import clsx from "clsx";
import { Person } from "components/oldDesignAssets/icons";
import { useConfirmationModal } from "contexts/confirmationModalContext";
import { useSnackBar } from "contexts/snackBarContext";
import { format } from "date-fns";
import { useRef, useState } from "react";
import { isValidDate } from "utils/globalUtils";
import useOnClickOutside from "../../../hooks/useClickOutside";
import {
  sendAssignNotification,
  sendBookingConfirmation,
  sendRescheduleNotification,
} from "../../../services/requestService";
import { requestStore } from "../../../stores/modalManager";
import Checkbox from "../checkbox";
import Footer from "../footer";
import Header from "../header";
import { ArrowLeft, Email, NotificationActive, Phone } from "../icons";
import AppLinks from "./appLinks";
import CreateRequest from "./createRequest";
import Request from "./request";

export default function Notification() {
  const {
    user,
    owner,
    _id: serviceId,
    bookingConfirmation,
    bookingConfirmationMethod = "SMS",
    isSendBookingConfirmation = false,
    providers,
    logs,
  }: any = requestStore.useState(s => ({ ...s.request, ...s.unsavedChanges }));
  const wrapperRef = useRef<HTMLUListElement | null>(null);
  const snackBar = useSnackBar();
  const { open } = useConfirmationModal();

  const userData = owner || user;

  const [showDropDown, setShowDropDown] = useState(false);

  useOnClickOutside(wrapperRef, () => {
    setShowDropDown(false);
  });

  const isBookingConfirmationSent = bookingConfirmation?.logs.length > 0;
  const isUserHasPhoneNumbers = userData?.phoneNumbers?.length;

  const isRescheduled = logs?.some(l => l.content.match("Schedule updated"));

  const lastBookingConfirmationSMS = logs?.findLast(l =>
    l.content.match("SMS sent to User for Booking Confirmation"),
  )?.date;

  const lastBookingConfirmation = logs?.findLast(l =>
    l.content.match(/SMS assignment|Email sent to User for Booking Confirmation/),
  );
  const lastRescheduleConfirmation = logs?.findLast(l => l.content.match("SMS reschedule"))?.date;

  const userPhoneNumber = `${userData?.phoneNumbers[0]?.ext}${userData?.phoneNumbers[0]?.number}`;

  const isSMS = bookingConfirmationMethod === "SMS";

  const showRequest = () => {
    requestStore.update((s: any) => {
      s.componentsToRender = <Request />;
    });
  };

  const showCreateRequest = e => {
    e.preventDefault();
    requestStore.update((s: any) => {
      s.componentsToRender = <CreateRequest />;
    });
  };

  const lastBookingConfirmationDateText =
    isValidDate(lastBookingConfirmation?.date) &&
    `${lastBookingConfirmation?.content?.startsWith("SMS") ? "SMS" : "Email"} Sent ${format(
      new Date(lastBookingConfirmation?.date),
      "dd MMM yyyy - HH:mm",
    )}`;

  const notifyVia = [
    {
      text: "SMS",
      icon: <Phone />,
      action: () => {
        requestStore.update((s: any) => {
          if (!isUserHasPhoneNumbers) s.unsavedChanges.isSendBookingConfirmation = false;
          s.unsavedChanges.bookingConfirmationMethod = "SMS";
        });
      },
    },
    {
      text: "Email",
      icon: <Email />,
      action: () => {
        if (!serviceId) return;
        requestStore.update((s: any) => {
          s.unsavedChanges.bookingConfirmationMethod = "Email";
        });
      },
    },
  ];

  const handleNotificationDropDown = () => {
    setShowDropDown(!showDropDown);
  };

  const onCheckSendBookingConfirmation = checked => {
    requestStore.update((s: any) => {
      s.unsavedChanges.isSendBookingConfirmation = checked;
    });
  };

  const resendBookingConfirmationHandler = async () => {
    try {
      const { data } = await sendBookingConfirmation(serviceId, { method: bookingConfirmationMethod });
      requestStore.update(s => {
        s.request = data.service;
      });

      snackBar.pop({ content: data.message });
    } catch (error) {
      snackBar.pop({ content: error.data?.toString(), alertProps: { severity: "error" } });
    }
  };

  const sendAssignNotificationHandler = async () => {
    try {
      const { data } = await sendAssignNotification(serviceId);

      snackBar.pop({ content: data.message });
    } catch (error) {
      snackBar.pop({ content: error.data?.toString(), alertProps: { severity: "error" } });
    }
  };

  const sendRescheduleNotificationHandler = async () => {
    try {
      const { data } = await sendRescheduleNotification(serviceId);

      snackBar.pop({ content: data.message });
    } catch (error) {
      snackBar.pop({ content: error.data?.toString(), alertProps: { severity: "error" } });
    }
  };

  const onClickBookingNotification = () => {
    open({
      title: `Booking ${isSMS ? "Notification" : "Confirmation"}`,
      description: `User will be sent a Booking Notification via ${bookingConfirmationMethod} to ${
        isSMS ? userPhoneNumber : userData?.email?.address
      }`,
      onConfirm: resendBookingConfirmationHandler,
    });
  };

  const onClickBookingConfirmation = () => {
    open({
      title: "Booking Confirmation",
      description: `User will be sent a Booking Confirmation via SMS to ${userPhoneNumber}`,
      onConfirm: sendAssignNotificationHandler,
    });
  };

  const onClickRescheduleConfirmation = () => {
    open({
      title: "Reschedule Confirmation",
      description: `User will be sent a Reschedule Confirmation via SMS to ${userPhoneNumber}`,
      onConfirm: sendRescheduleNotificationHandler,
    });
  };

  const isShouldDisable = !serviceId || (isSMS && !isUserHasPhoneNumbers);

  return (
    <div className="modalContent notificationUM">
      <Header>
        <ArrowLeft role="button" aria-label="backButton" onClick={serviceId ? showRequest : showCreateRequest} />
        <div className="title">Notifications</div>
      </Header>
      <div className="content">
        <div className="container">
          <div className="profileSection">
            <div className="title">
              <div className="icon">
                <Person />
              </div>
              <div className="text">{userData?.name}</div>
            </div>
          </div>
          <AppLinks user={userData} defaultChecked={false} onClickCheckBox={null} />
          <hr />
          <div className="requestRelatedSection">
            <div className="title">
              <div className="icon">
                <NotificationActive />
              </div>
              <div className="text">Request Related</div>
            </div>
            <div className="action" onClick={handleNotificationDropDown}>
              <div className="icon">
                {notifyVia.find(notification => notification.text === bookingConfirmationMethod)?.icon}
              </div>
              <div className="text">
                {notifyVia.find(notification => notification.text === bookingConfirmationMethod)?.text}
              </div>
              <ul className={`dropdownNotification ${showDropDown ? "show" : ""}`} ref={wrapperRef}>
                {notifyVia?.map(notification => {
                  const shouldDisableEmail = !serviceId && notification.text === "Email";

                  return (
                    !shouldDisableEmail && (
                      <li
                        key={notification.text}
                        className={clsx({ active: notification.text === bookingConfirmationMethod })}
                        onClick={notification.action}>
                        <div className="icon">{notification.icon}</div>
                        <div className="text">{notification.text}</div>
                      </li>
                    )
                  );
                })}
              </ul>
            </div>
          </div>
          <div className="sendBookingSection">
            <Grid container direction="column" gap="33px">
              {serviceId ? (
                <SendNotificationButton
                  title={`Booking ${isSMS ? "Notification" : "Confirmation"}`}
                  description={
                    isSMS
                      ? "Trigger SMS notification when appointment has yet to be confirmed by us"
                      : "Trigger Email notification when the appointment is assigned to a provider"
                  }
                  date={
                    isSMS
                      ? isValidDate(lastBookingConfirmationSMS) &&
                        `SMS Sent ${format(new Date(lastBookingConfirmationSMS), "dd MMM yyyy - HH:mm")}`
                      : lastBookingConfirmationDateText || "No Notification was sent upon creation"
                  }
                  linkText={`${
                    (isSMS && lastBookingConfirmationSMS) || (!isSMS && lastBookingConfirmationDateText)
                      ? "Resend"
                      : "Send"
                  } Booking Notification to Patient`}
                  disabled={isShouldDisable || (!isSMS && !providers?.length)}
                  onClick={onClickBookingNotification}
                />
              ) : (
                <Tooltip arrow title="This user doesn't have phone number" disableHoverListener={isUserHasPhoneNumbers}>
                  <Checkbox
                    id="sendBookingNotification"
                    text={
                      (
                        <>
                          <span style={{ fontWeight: 600 }}>Booking Notification</span>
                          <span className="subText">
                            Trigger SMS notification when appointment has yet to be confirmed by us
                          </span>
                        </>
                      ) as unknown as string
                    }
                    onChange={onCheckSendBookingConfirmation}
                    defaultChecked={isBookingConfirmationSent || isSendBookingConfirmation}
                    disabled={!isUserHasPhoneNumbers}
                    className={clsx({ disabled: !isUserHasPhoneNumbers })}
                  />
                </Tooltip>
              )}

              {serviceId && isSMS && (
                <>
                  <SendNotificationButton
                    title="Booking Confirmation"
                    description="Trigger SMS notification when the appointment is assigned to a provider"
                    date={lastBookingConfirmationDateText}
                    linkText={`${lastBookingConfirmation ? "Resend" : "Send"} Booking Confirmation to Patient`}
                    disabled={isShouldDisable || !providers?.length}
                    onClick={onClickBookingConfirmation}
                  />

                  <SendNotificationButton
                    title="Reschedule Notice"
                    description="Trigger SMS notification when the appointment has been rescheduled"
                    date={
                      isValidDate(lastRescheduleConfirmation) &&
                      `SMS Sent ${format(new Date(lastRescheduleConfirmation), "dd MMM yyyy - HH:mm")}`
                    }
                    linkText={`${lastRescheduleConfirmation ? "Resend" : "Send"} Reschedule Notice to Patient`}
                    disabled={isShouldDisable || !isRescheduled}
                    onClick={onClickRescheduleConfirmation}
                  />
                </>
              )}
            </Grid>
          </div>
        </div>
      </div>

      <Footer>
        <div className="footerContainer">
          <Link
            color="secondary.main"
            underline="none"
            sx={{ cursor: "pointer" }}
            onClick={serviceId ? showRequest : showCreateRequest}>
            Done
          </Link>
        </div>
      </Footer>
    </div>
  );
}

function SendNotificationButton({ title, description, date, linkText, disabled, onClick }) {
  return (
    <Grid container direction="column" pl="42px">
      <Typography fontSize={12} fontWeight={600}>
        {title}
      </Typography>

      <Typography fontSize={12} color="#6c6c6c">
        {description}
      </Typography>

      {date && (
        <Typography mt={1} fontSize={12} color="#6c6c6c">
          {date}
        </Typography>
      )}

      <Link
        mt={2}
        color={disabled ? "text.secondary" : "#126EF8"}
        sx={{ cursor: disabled ? "not-allowed" : "pointer" }}
        onClick={!disabled ? onClick : undefined}>
        {linkText}
      </Link>
    </Grid>
  );
}
