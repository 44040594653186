import { useConfirmationModal } from "contexts/confirmationModalContext";
import { useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import LoadingSection from "../../components/loadingSection";
import useOnClickOutside from "../../hooks/useClickOutside";
import {
  assignedToAccepted,
  completedToVisitEnd,
  inProgressToInTransit,
  inTransitToAssigned,
  visitEndToVisitStart,
} from "../../services/requestService";
import { requestStore } from "../../stores/modalManager";
import { store } from "../../stores/storeManager";
import Request from "../universalModal/pages/request";
import Cancellation from "./pages/cancellation";
import { Typography } from "@mui/material";
import { useSnackBar } from "contexts/snackBarContext";

const statusSequence = [
  { title: "draft" },
  { title: "accepted", action: assignedToAccepted },
  { title: "assigned", action: inTransitToAssigned },
  { title: "inTransit", action: inProgressToInTransit },
  { title: "inProgress", action: visitEndToVisitStart },
  { title: "visitEnd", action: completedToVisitEnd },
  { title: "completed" },
  { title: "cancelled" },
];

export default function StatusTag({ children, statusName = "", className = "", isDisabled = false, ...props }) {
  const wrapperRef = useRef();
  const snackBar = useSnackBar();
  const { open } = useConfirmationModal();

  const { t = () => "" } = useTranslation();
  const request = requestStore.useState(s => s.request);
  const { language } = store.useState(s => ({
    language: s.language,
  }));

  const [showDropDown, setShowDropDown] = useState(false);

  const showSubscreen = subscreen => {
    requestStore.update(s => {
      s.componentsToRender = subscreen;
    });
  };

  useOnClickOutside(wrapperRef, () => {
    setShowDropDown(false);
  });

  const serviceStatus = t(`${language}.serviceStatus`);

  const dropDownStatus = useMemo(() => {
    if (["cancelled", "discharged"].includes(statusName)) return [];

    const isClinic = request.type === "Clinic";
    const isDelivery = request.type === "Delivery";
    const currentStatusIndex = statusSequence.findIndex(status => status.title === statusName);

    if (["assigned", "inTransit", "inProgress", "visitEnd", "completed"].includes(statusName)) {
      const previousStep = isClinic && statusName === "inProgress" ? 2 : 1;
      const statuses = [];

      if (!isDelivery || statusName !== "completed") statuses.push(statusSequence[currentStatusIndex - previousStep]);
      statuses.push(statusSequence[currentStatusIndex]);

      if (statusName !== "completed") statuses.push(statusSequence[statusSequence.length - 1]);

      return statuses;
    }

    return [statusSequence[currentStatusIndex], statusSequence[statusSequence.length - 1]];
  }, [request.type, statusName]);

  const isDropDownEnabled = !isDisabled && dropDownStatus?.length > 1;

  const handleStatusDropDown = () => {
    setShowDropDown(!showDropDown);
  };

  const handleStatusClick = status => {
    if (status.title === "cancelled") {
      showSubscreen(<Cancellation />);
    } else {
      if (!status.action) return;

      open({
        title: "Confirm State Change",
        description: (
          <Typography variant="body2" color="text.secondary">
            Are you sure you want to change the state to <b>{serviceStatus[status.title]}</b>?
          </Typography>
        ),
        onConfirm: () => onClickConfirm(status.action),
      });
    }
  };

  const onClickConfirm = async action => {
    try {
      requestStore.update(s => {
        s.componentsToRender = <LoadingSection className="modalContent">Loading request data</LoadingSection>;
      });
      const result = await action(request._id, request.logistic);
      snackBar.pop({
        content: result.data?.message,
      });
      requestStore.update(s => {
        s.request = result.data.service;
        s.unsavedChanges = {
          billing: { ...s.request.billing },
          schedule: { ...s.request.schedule },
          providers: [...s.request.providers],
          isDirty: {},
        };
      });
    } catch (error) {
      snackBar.pop({
        content: error.data?.toString(),
        alertProps: { severity: "error" },
      });
    } finally {
      requestStore.update(s => {
        s.componentsToRender = <Request />;
      });
    }
  };

  return (
    <div className="statusContainerUM" ref={wrapperRef}>
      <div
        className={`statusTagUM ${className} ${isDropDownEnabled && "dropDown"} ${showDropDown && "open"}`}
        {...props}
        onClick={isDropDownEnabled ? handleStatusDropDown : undefined}>
        {children} {isDropDownEnabled && <span className="caret" />}
      </div>
      {isDropDownEnabled && (
        <ul className={`dropdownStatusTagUM ${showDropDown && "show"}`}>
          {dropDownStatus &&
            dropDownStatus.map(
              (status, index) =>
                status && (
                  <li key={index} className={`${status.title === statusName && "active"}`}>
                    <div
                      className={`statusTagUM ${status.title}`}
                      onClick={status.title !== statusName ? () => handleStatusClick(status) : undefined}>
                      {serviceStatus[status.title]}
                    </div>
                  </li>
                ),
            )}
        </ul>
      )}
    </div>
  );
}
