import CreditCardRoundedIcon from "@mui/icons-material/CreditCardRounded";
import WavPayIcon from "components/icons/WavPay";
import { Dollar } from "components/oldDesignAssets/icons";
import { Bank, CDA, DuitNow, Nets, PayNow, Wallet } from "components/universalModal/icons";
import { countries, getEmojiFlag } from "countries-list";

export const availableCountries = ["sg", "my"];

export const defaultCountry = ["sg"];

export const countryList = Object.keys(countries).map(alpha2 => {
  const c = countries[alpha2];
  return {
    alpha2,
    flag: getEmojiFlag(alpha2),
    name: c.name,
    phone: parseInt(c.phone),
  };
});

export const sortedCountries = [...countryList].sort((a, b) => (a.phone > b.phone ? 1 : -1));

export const role = {
  superAdmin: "superAdmin",
  avixoLinker: "avixoLinker",
};

export const businessSizes = ["0-10", "11-50", "51-200", "201-500", "501-1000", "1001-5000", "5000+"];

export const cancellationReasons = {
  SG: [
    "Waited too long for booking to be confirmed",
    "Unsuitable appointment time",
    "Found a cheaper telehealth service",
    "Emergency ambulance required",
    "Made a double/wrong booking",
    "Wrong details provided",
    "Change of Care Plan",
    "Testing",
    "Others",
  ],
  MY: [
    "Waited too long for booking to be confirmed",
    "Unsuitable appointment time",
    "Found a cheaper telehealth service",
    "Emergency ambulance required",
    "Made a double/wrong booking",
    "Wrong details provided",
    "Clinic location too far",
    "Change of Care Plan",
    "Testing",
    "Others",
  ],
  IN: [
    "Waited too long for booking to be confirmed",
    "Unsuitable appointment time",
    "Found a cheaper telehealth service",
    "Emergency ambulance required",
    "Made a double/wrong booking",
    "Wrong details provided",
    "Change of Care Plan",
    "Testing",
    "Others",
  ],
};

export const providersAvailable = [
  { label: "Doctor", value: "General Practitioner", selected: true },
  { label: "Nurse", value: "Nurse", selected: true },
  { label: "Ambulance", value: "Ambulance", selected: true },
  { label: "Cardiologist", value: "Cardiologist", selected: true },
  { label: "Paediatrician", value: "Paediatrician", selected: true },
  { label: "Physiotherapist", value: "Physiotherapist", selected: true },
  { label: "Speech Therapist", value: "Speech Therapist", selected: true },
  { label: "Nephrologist", value: "Nephrologist", selected: true },
  { label: "Dietitian", value: "Dietitian", selected: true },
  { label: "Swabber", value: "Swabber", selected: true },
  { label: "Occupational Therapist", value: "Occupational Therapist", selected: true },
  { label: "TCM Practitioner", value: "TCM Practitioner", selected: true },
  { label: "Rider", value: "Rider", selected: true },
  { label: "Psychologist", value: "Psychologist", selected: true },
  { label: "Counsellor", value: "Counsellor", selected: true },
  { label: "Phlebotomist", value: "Phlebotomist", selected: true },
];

export const providerList = providersAvailable.map(({ label, value }) => ({ label, value }));

export const availableServices = ["Visit", "Video", "Clinic", "Delivery"];

export const serviceScheduleOffset = {
  Visit: { startOffset: 30, endOffset: 30 },
  Video: { startOffset: 0, endOffset: 0 },
  Clinic: { startOffset: 0, endOffset: 0 },
  Delivery: { startOffset: 0, endOffset: 0 },
};

export const minimumTaskInterval = 10;

export const paymentMethodIcons = {
  duitNow: <DuitNow />,
  payNow: <PayNow />,
  wavPay: <WavPayIcon />,
  nets: <Nets />,
  cda: <CDA />,
  bankTransfer: <Bank />,
  cash: <Dollar />,
  card: <CreditCardRoundedIcon htmlColor="#979797" />,
  other: <Wallet />,
};

export const gender = {
  M: "Male",
  F: "Female",
};

export const prefixes = { "Dr.": "Dr. (Doctor)", "Prof.": "Prof. (Professor)", SN: "SN (Staff Nurse)" };

export const serviceStatuses = [
  { label: "Draft", value: "draft" },
  { label: "Received", value: "accepted" },
  { label: "Assigned", value: "assigned" },
  { label: "In Transit", value: "inTransit" },
  { label: "Visit Start", value: "inProgress" },
  { label: "Visit End", value: "visitEnd" },
  { label: "Completed", value: "completed" },
  { label: "Cancelled", value: "cancelled" },
];

export const caseTypes = [
  // Old case types that can still being use to create Services
  "h-prog",

  // New Segment Types
  "B2C",
  "Other B2B",
  "h-ward",
  "Baby Vaccination",
  "Virtual Clinic",
];

export const caseTypesFilter = [
  // Old case types being put here to enable users to filter past cases
  "acute",
  "nursing",
  "preventive",
  "palliative",
  "Rehab",
  "PHF Rehab",
  "CP Rehab",
  "Post Op",
  "Wound Care",
  "Guardian",
  "cdhm",
  "Corporate",

  ...caseTypes,
];

export const caseTypesRosterFilter = caseTypesFilter.map(c => ({ label: c, value: c, selected: true }));

export const corporateCaseTypes = { SG: ["h-ward", "h-prog", "Other B2B"], MY: [] };

export const programRequiredCases = {
  SG: ["B2C", "Other B2B", "h-ward", "Baby Vaccination", "Virtual Clinic"],
  MY: ["h-ward"],
  IN: ["B2C"],
};

export const packageRequiredCases = {
  SG: ["B2C", "Other B2B", "h-ward", "Baby Vaccination", "Virtual Clinic"],
  MY: ["h-ward"],
  IN: ["B2C"],
};

export const rosterColors = {
  services: [
    {
      name: "Home Visit Doctor",
      initial: "HV",
      type: "Visit",
      unassigned: {
        background: { color: "#EAF6E3" },
        border: {
          style: "dashed",
          color: "#56A027",
        },
      },
      inProgress: {
        background: { color: "#75F94C" },
        border: {
          style: "solid",
          color: "#56A027",
        },
      },
      completed: {
        background: { color: "#DCE8D5" },
        border: {
          style: "solid",
          color: "#56A027",
        },
      },
    },
    {
      name: "Home Visit Nurse",
      initial: "HV",
      type: "Visit",
      specialty: "Nurse",
      unassigned: {
        background: { color: "#FFEBE4" },
        border: {
          style: "dashed",
          color: "#E28467",
        },
      },
      inProgress: {
        background: { color: "#FFB49C" },
        border: {
          style: "solid",
          color: "#E28467",
        },
      },
      completed: {
        background: { color: "#FFDCD1" },
        border: {
          style: "solid",
          color: "#E28467",
        },
      },
    },
    {
      name: "Delivery",
      initial: "Dlvr",
      type: "Delivery",
      unassigned: {
        background: { color: "#F4D8D8" },
        border: {
          style: "dashed",
          color: "#BF2424",
        },
      },
      inProgress: {
        background: { color: "#EF7676" },
        border: {
          style: "solid",
          color: "#BF2424",
        },
      },
      completed: {
        background: { color: "#F0BABA" },
        border: {
          style: "solid",
          color: "#BF2424",
        },
      },
    },
    {
      name: "Clinic",
      initial: "Cl",
      type: "Clinic",
      unassigned: {
        background: { color: "#E3E8F3" },
        border: {
          style: "dashed",
          color: "#204190",
        },
      },
      inProgress: {
        background: { color: "#7893D3" },
        border: {
          style: "solid",
          color: "#204190",
        },
      },
      completed: {
        background: { color: "#D3DBEC" },
        border: {
          style: "solid",
          color: "#204190",
        },
      },
    },
    {
      name: "Video Consultation",
      initial: "VC",
      type: "Video",
      width: 10,
      unassigned: {
        background: { color: "#E0EAFF" },
        border: {
          style: "dashed",
          color: "#234EBE",
        },
      },
      inProgress: {
        background: { color: "#2295FF" },
        border: {
          style: "solid",
          color: "#234EBE",
        },
      },
      completed: {
        background: { color: "#BEE0FF" },
        border: {
          style: "solid",
          color: "#234EBE",
        },
      },
    },
  ],
  tasks: {
    break: {
      name: "Break",
      color: "#FFFC54",
      border: "#D7D30C",
    },
    corporate: {
      name: "Corporate",
      color: "#8697F1",
      border: "#4A5FCE",
    },
    labDispatch: {
      name: "Lab Dispatch",
      color: "#B9EB92",
      border: "#78BA44",
    },
    others: {
      name: "Others",
      color: "#B0C7C9",
      border: "#799A9D",
    },
    nursingHome: {
      name: "Nursing Home",
      color: "#FE82F2",
      border: "#E24DD3",
    },
    meeting: {
      name: "Meeting",
      color: "#B9A4E7",
      border: "#7B5FB9",
    },
    tmars: {
      name: "TMARS",
      color: "#83E8FF",
      border: "#40A0B6",
    },
    seafarer: {
      name: "Seafarer",
      color: "#84D6A4",
      border: "#36985C",
    },
  },
  timeblocks: {
    visit: {
      name: "Home Visit",
      color: "#5CA7A4",
      border: "#357C79",
    },
    video: {
      name: "Video Consult",
      color: "#6FAAE8",
      border: "#2F70B5",
    },
    clinic: {
      name: "Clinic",
      color: "#2A3E7F",
      border: "#162351",
    },
    delivery: { name: "Delivery", color: "#FAE6E0", border: "#E0A390" },
    adhoc: {
      name: "Adhoc",
      color: "#87458F",
      border: "#5A195F",
    },
    roster: {
      name: "Service Combination",
      color: "#474AC6",
      border: "#2428AB",
    },
  },
};
