import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { Accordion, AccordionDetails, AccordionSummary, Chip, Grid, Typography } from "@mui/material";
import { useState } from "react";
import { Link } from "react-router-dom";

interface CaseInformationProps {
  service: {
    id: number;
    case: Record<string, any>;
    user: {
      _id: string;
    };
    isFollowUp: boolean;
  };
  showLabelOnly?: boolean;
}

export default function CaseInformation({ service, showLabelOnly = false }: CaseInformationProps) {
  const [isCaseDetailsExpanded, setIsCaseDetailsExpanded] = useState(false);

  const caseInformationLabel = [
    service.case.type,
    service.case.program?.title,
    service.case.package?.name,
    service.case.business?.tag,
  ]
    .filter(Boolean)
    .join(" ");
  const isBabyVaccinationCase = service.case.type === "Baby Vaccination";
  const relatedAppointments = [...service.case.drafts, ...service.case.services]
    .sort((a, b) => a.id - b.id)
    .filter(appointment => appointment.id !== service.id);

  const onToggleCaseDetails = () => setIsCaseDetailsExpanded(prev => !prev);

  const followUpLabel = service.isFollowUp ? (
    <Typography fontSize={12} color="wildTide.500" fontWeight={700}>
      Follow-Up
    </Typography>
  ) : null;

  if (isBabyVaccinationCase && !showLabelOnly)
    return (
      <Accordion
        expanded={isCaseDetailsExpanded}
        onChange={onToggleCaseDetails}
        elevation={0}
        sx={{ "&:hover": { backgroundColor: "#F7F7F8" } }}
        disableGutters>
        <AccordionSummary sx={{ px: 1, minHeight: "36px", ".MuiAccordionSummary-content": { my: 0.75 } }}>
          <Grid container alignItems="center" gap={1}>
            {isCaseDetailsExpanded ? (
              <KeyboardArrowDownIcon sx={{ color: "icon.main" }} />
            ) : (
              <KeyboardArrowRightIcon sx={{ color: "icon.main" }} />
            )}

            <Typography fontSize={12}>
              {caseInformationLabel}{" "}
              <Link
                to={`patient/${service.user._id}?tab=cases&jumpToCaseId=${service.case._id}`}
                target="_blank"
                style={{ textDecorationLine: "underline", fontWeight: 700 }}>
                {`C-${service.case.id}`}
              </Link>
            </Typography>

            {followUpLabel}
          </Grid>
        </AccordionSummary>

        <AccordionDetails>
          <Grid container pl={3} gap={1}>
            {relatedAppointments.length === 0 && (
              <Typography fontSize={12} color="text.secondary">
                No related appointments
              </Typography>
            )}

            {relatedAppointments.map(appointment => (
              <Chip
                key={appointment._id}
                label={<Typography fontSize={12}>S-{appointment.id}</Typography>}
                size="small"
              />
            ))}
          </Grid>
        </AccordionDetails>
      </Accordion>
    );

  return (
    <Grid container alignItems="center" gap={1}>
      <Typography fontSize={12} data-testid="case-information">
        {caseInformationLabel} C-{service.case.id}
      </Typography>

      {followUpLabel}
    </Grid>
  );
}
