import { Trash } from "components/oldDesignAssets/icons";
import { useState } from "react";
import { deleteMedia } from "../../services/requestService";
import { requestStore } from "../../stores/modalManager";
import { formattedAttachmentName, formattedAttachmentSize } from "../../utils/globalUtils";
import ImageViewer from "../global/imageViewer";
import Loader from "../global/loader";
import { Download } from "./icons";
import { useSnackBar } from "contexts/snackBarContext";

export default function MediaAttachment({ media, alt = "attachment", isPayment = false }) {
  const snackBar = useSnackBar();
  const request = requestStore.useState(s => ({ ...s.request, ...s.unsavedChanges }));
  const mediaRecord = request.records.find(r => r.type === "media");
  const [isLoading, setIsLoading] = useState(false);

  const [imageURL, setImageURL] = useState();

  const removeMedia = async (e, media) => {
    setIsLoading(true);
    e.stopPropagation();
    if (media && media.data.url && mediaRecord) {
      try {
        const { data } = await deleteMedia(request._id, { mediaRef: media.data.id, recordRef: mediaRecord._id });
        requestStore.update(s => {
          s.request = data.service;
        });
      } catch (error) {
        snackBar.pop({
          content: error.data?.toString(),
          alertProps: { severity: "error" },
        });
      }
    }
    setIsLoading(false);
  };

  if (isLoading) return <Loader />;
  return (
    <div className="mediaGroup">
      <ImageViewer src={media?.data?.url} alt={alt} loadedCallback={setImageURL} />

      <div className="hover">
        <div className="mediaInformation">
          <div className="name">{media.data.fileName ? formattedAttachmentName(media.data.fileName) : ""}</div>
          <div className="size">{media.data.size ? formattedAttachmentSize(media.data.size) : ""}</div>
        </div>
        <div className="actions">
          <a href={imageURL} download={media.data.fileName} onClick={e => e.stopPropagation()}>
            <Download />
          </a>
          {!isPayment && <Trash onClick={e => removeMedia(e, media)} />}
        </div>
      </div>
    </div>
  );
}
