import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import { Box, Button, Dialog, Divider, Grid, IconButton, Typography } from "@mui/material";
import { Fragment, useState } from "react";
import { useSnackBar } from "../../../contexts/snackBarContext";
import { deleteAddress } from "../../../services/userService";
import { requestStore } from "../../../stores/modalManager";
import { store } from "../../../stores/storeManager";
import { getFormattedAddress } from "../../../utils/globalUtils";
import Header from "../header";
import { ArrowLeft } from "../icons";
import AddressForm from "./addressForm";
import CreateRequest from "./createRequest";
import EditRequest from "./editRequest";

export default function AddressList() {
  const request = requestStore.useState(s => ({ ...s.request, ...s.unsavedChanges }));
  const alpha2 = store.useState(s => s.country.alpha2);
  const [addressIdToDelete, setAddressIdToDelete] = useState("");
  const [isDeleting, setIsDeleting] = useState(false);

  const snackBar = useSnackBar();

  const savedAddresses = request.owner?.addresses || request.user.addresses || [];

  const setAddress = address => () => {
    const addressType =
      request.type === "Delivery" && request.specialty === "Rider" ? "destinationAddress" : "requestedAddress";

    requestStore.update(s => {
      s.unsavedChanges.schedule[addressType] = address;
      s.unsavedChanges.isDirty.address = true;
    });

    onClickBack();
  };

  const onClickBack = () => {
    requestStore.update(s => {
      s.componentsToRender = request._id ? <EditRequest /> : <CreateRequest />;
    });
  };

  const onClickAdd = () => {
    requestStore.update(s => {
      s.componentsToRender = <AddressForm />;
    });
  };

  const onClickEdit = address => e => {
    e.stopPropagation();

    requestStore.update(s => {
      s.componentsToRender = <AddressForm address={address} />;
    });
  };

  const onClickDelete = addressId => async e => {
    e.stopPropagation();

    setAddressIdToDelete(addressId);
  };

  const onClickConfirmDelete = async () => {
    const userRef = request.owner?._id || request.user._id;
    let response;

    try {
      setIsDeleting(true);

      response = await deleteAddress(userRef, { _id: addressIdToDelete, alpha2 });

      requestStore.update(s => {
        const patientFieldName = request.owner ? "owner" : "user";
        const patient = s.unsavedChanges[patientFieldName] || s.request[patientFieldName];
        patient.addresses = response.data.addresses;
      });

      snackBar.pop({ content: response.data.message });
    } catch (error) {
      snackBar.pop({ content: error.data?.toString(), alertProps: { severity: "error", color: "error" } });
    } finally {
      onClickCancelDelete();
      setIsDeleting(false);
    }
  };
  const onClickCancelDelete = () => {
    setAddressIdToDelete("");
  };

  return (
    <>
      <Box className="modalContent addressListUM">
        <Header>
          <ArrowLeft role="button" aria-label="backButton" onClick={onClickBack} />
          <Box className="title">Address List</Box>
        </Header>

        <Box className="content">
          <Grid container gap={2} pl={2} pr={1} wrap="nowrap">
            <LocationOnIcon htmlColor="#979797" sx={{ mt: "10px" }} />

            <Grid item xs overflow="hidden">
              {savedAddresses.length === 0 ? (
                <Box color="#c4c4c4" pt="10px" pl="6px">
                  No saved addresses
                </Box>
              ) : (
                savedAddresses
                  .filter(a => a.alpha2 === alpha2)
                  .map((a, i) => {
                    return (
                      <Fragment key={i}>
                        <Grid
                          container
                          className="addressItem"
                          alignItems="center"
                          wrap="nowrap"
                          onClick={setAddress(a)}>
                          <Grid item xs={10}>
                            <Box color="#4f657a">{a.title}</Box>
                            <Box className="addressOneLine">{getFormattedAddress(a)}</Box>
                          </Grid>

                          <Grid item xs={2} container justifyContent="end" wrap="nowrap">
                            <IconButton size="small" onClick={onClickEdit(a)}>
                              <EditIcon htmlColor="#9BB0C4" fontSize="small" />
                            </IconButton>
                            <IconButton size="small" onClick={onClickDelete(a._id)}>
                              <DeleteIcon htmlColor="#9BB0C4" fontSize="small" />
                            </IconButton>
                          </Grid>
                        </Grid>

                        <Divider sx={{ margin: "0px 8px" }} />
                      </Fragment>
                    );
                  })
              )}
            </Grid>
          </Grid>
        </Box>

        <Divider />

        <Box p={2} textAlign="right">
          <Button
            className="btnAdd"
            onClick={onClickAdd}
            data-testid="addAddressButton"
            variant="contained"
            disableElevation>
            Add Address
          </Button>
        </Box>
      </Box>

      {addressIdToDelete.length > 0 && (
        <Dialog open>
          <Box p={2}>
            <Box color="#56738F">
              <Typography component="div" fontSize="18px" fontWeight={600}>
                Confirm Delete
              </Typography>

              <Typography component="div" mt={2}>
                This will remove the address from the list.
              </Typography>

              <Typography component="div" mt={2}>
                Are you sure you want delete?
              </Typography>
            </Box>

            <Grid container justifyContent="space-between" mt={3}>
              <Button
                className="btnCancel"
                onClick={onClickCancelDelete}
                sx={{ color: "#e4104f" }}
                disabled={isDeleting}
                disableElevation>
                Cancel
              </Button>

              <Button
                className="btnConfirm"
                onClick={onClickConfirmDelete}
                variant="contained"
                data-testid="confirm"
                sx={{
                  background: "#00c3b5",
                  color: "white",

                  "&:hover": {
                    background: "#00c3b5",
                    color: "white",
                  },
                }}
                disabled={isDeleting}
                disableElevation>
                Confirm
              </Button>
            </Grid>
          </Box>
        </Dialog>
      )}
    </>
  );
}
