import { Typography, TypographyProps } from "@mui/material";
import { store } from "../../stores/storeManager";

interface PriceProps extends TypographyProps {
  value: number;
  currency?: string;
  prefix?: string;
  postfix?: string;
  string?: boolean;
}

export default function Price({ currency, value, prefix = "", postfix = "", ...textProps }: Readonly<PriceProps>) {
  const { currencySymbol, currencySymbolPosition } = store.useState(s => ({
    currencySymbol: s.country?.currencySymbol ?? "",
    currencySymbolPosition: s.country?.currencySymbolPosition ?? "before",
  }));
  const currencySymbolLabel = currency ?? currencySymbol ?? "";
  const text =
    currencySymbolPosition === "before"
      ? [prefix, currencySymbolLabel, value, postfix].join("")
      : [prefix, value, currencySymbolLabel, postfix].join("");

  return <Typography {...textProps}>{isNaN(value) ? "-" : text}</Typography>;
}
