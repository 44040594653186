import { useState } from "react";
import URI from "urijs";
import AutoComplete from "../../components/universalModal/autoComplete";
import { store } from "../../stores/storeManager";
import { getFormattedAddress, truncateString } from "../../utils/globalUtils";
import { useSnackBar } from "contexts/snackBarContext";

export default function OneMapAutocomplete({
  disabled,
  address = {},
  placeholder,
  className = "",
  onChange = () => {},
}) {
  const snackBar = useSnackBar();
  const { alpha2 } = store.useState(s => s.country);
  const [addressList, setAddressList] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const fetchAddress = async ({ searchVal, pageNum }) => {
    if (searchVal) {
      try {
        const oneMapAPI = new URI(`${process.env.REACT_APP_ONEMAP_API}`);
        oneMapAPI.addQuery("searchVal", searchVal);
        oneMapAPI.addQuery("returnGeom", "Y");
        oneMapAPI.addQuery("getAddrDetails", "Y");
        oneMapAPI.addQuery("pageNum", pageNum);
        const data = await (await fetch(oneMapAPI)).json();
        pageNum > 1 ? setAddressList([...addressList, ...data.results]) : setAddressList(data.results);
        setPage(data.pageNum);
        setTotalPages(data.totalNumPages);
      } catch (error) {
        snackBar.pop({
          content: error.data?.toString(),
          alertProps: { severity: "error" },
        });
      }
    } else {
      setAddressList([]);
    }
  };
  const onChangeAddress = addressData => {
    setAddressList([]);
    const addressToUpdate = {
      line1: `${addressData.BLK_NO && addressData.BLK_NO !== "NIL" ? `${addressData.BLK_NO}, ` : ""}${
        addressData.ROAD_NAME && addressData.ROAD_NAME !== "NIL" ? `${addressData.ROAD_NAME}, ` : ""
      }${addressData.BUILDING && addressData.BUILDING !== "NIL" ? addressData.BUILDING : ""}`,
      postCode: addressData.POSTAL && addressData.POSTAL !== "NIL" ? addressData.POSTAL : "",
      city: "Singapore",
      alpha2: alpha2,
      coordinates: {
        lat: addressData.LATITUDE,
        lng: addressData.LONGITUDE,
      },
    };
    addressToUpdate.oneLine = getFormattedAddress(addressToUpdate);
    onChange(addressToUpdate);
  };

  return (
    <AutoComplete
      className={`addressAutocompleteUM ${className}`}
      autoFocus={false}
      defaultValue={address.postCode || ""}
      onSelect={i => onChangeAddress(addressList[i])}
      list={addressList.map(({ SEARCHVAL, ADDRESS, POSTAL }) => ({
        title: truncateString({ string: SEARCHVAL, limit: 55 }),
        subtitle: truncateString({ string: ADDRESS, limit: 70 }),
        postcode: POSTAL,
      }))}
      fetch={searchVal => fetchAddress({ searchVal, pageNum: 1 })}
      showLoaderInView={totalPages > page}
      onLoaderInView={searchVal => {
        const nextPage = page + 1;
        setPage(nextPage);
        fetchAddress({ searchVal, pageNum: nextPage });
      }}
      placeholder={placeholder}
      pattern={/^[0-9\b]+$/}
      disabled={disabled}
    />
  );
}
