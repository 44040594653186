import { Store } from "pullstate";

const initialState = {
  user: { paymentMethods: [], phoneNumbers: [], addresses: [] },
  owner: { paymentMethods: [], phoneNumbers: [], relatives: [], addresses: [] },
  billing: { totals: {}, code: {} },
  type: "Visit",
  specialty: "General Practitioner",
  schedule: {
    isPickup: false,
    requestedAddress: { coordinates: {} },
    destinationAddress: { coordinates: {} },
  },
  items: [],
  records: [
    { type: "symptoms", data: { symptoms: [] } },
    { type: "clinicalNotes", data: { clinicalNotes: [{ message: "" }] } },
    { type: "jarvisNotes", data: { jarvisNotes: [{ message: "" }] } },
  ],
  case: {},
  origin: "",
  isIncludeVideo: false,
  isDirty: {},
};

const config = {
  showProviderSection: false,
  shouldDisableAssign: false,
};

export const requestStore = new Store({
  initialState,
  request: {},
  unsavedChanges: {},
  componentsToRender: null,
  destinationItems: [],
  addonItems: [],
  serviceItems: [],
  symptoms: [],
  config,
  hideModal: () => {},
});

export default function ModalStore() {
  return null;
}
