import URI from "urijs";
import http from "./httpService";

// USMS
export function getPatientsFromUSMS(query) {
  const uri = new URI(`${process.env.REACT_APP_API_USMS_ENDPOINT}/users/patient`);
  if (query) uri.addSearch(query);
  return http.get(uri.toString());
}

export function getUser(id, query) {
  const uri = new URI(`${process.env.REACT_APP_API_USMS_ENDPOINT}/user/${id}`);
  if (query) uri.addSearch(query);
  return http.get(uri.toString());
}

export function updateUser(id, data) {
  return http.put(`${process.env.REACT_APP_API_USMS_ENDPOINT}/user/${id}`, data);
}

export function addUser(data) {
  return http.post(`${process.env.REACT_APP_API_USMS_ENDPOINT}/user/patient`, data);
}

export function createRelative(data) {
  return http.post(`${process.env.REACT_APP_API_USMS_ENDPOINT}/user/relative`, data);
}

export function addAddress(id, data) {
  return http.post(`${process.env.REACT_APP_API_USMS_ENDPOINT}/user/${id}/address`, data);
}

export function updateAddress(id, data) {
  return http.put(`${process.env.REACT_APP_API_USMS_ENDPOINT}/user/${id}/address`, data);
}

export function deleteAddress(id, data) {
  return http.delete(`${process.env.REACT_APP_API_USMS_ENDPOINT}/user/${id}/address`, { data });
}

// Non USMS
export function addPayment(id, data) {
  return http.post(`${process.env.REACT_APP_API_REST_ENDPOINT}/user/${id}/payment`, data);
}

export function deletePayment(id, data) {
  return http.put(`${process.env.REACT_APP_API_REST_ENDPOINT}/user/${id}/payment`, data);
}

export function addAvatar(data) {
  return http.post(`${process.env.REACT_APP_API_REST_ENDPOINT}/user/avatar`, data, {
    headers: { "Content-Type": "multipart/form-data" },
  });
}

export function sendAppLinkSMS(id, alpha2) {
  return http.post(`${process.env.REACT_APP_API_REST_ENDPOINT}/user/${id}/notify/app-link`, { alpha2 });
}

export function createPasswordToken(id) {
  return http.put(`${process.env.REACT_APP_API_REST_ENDPOINT}/user/${id}/passwordToken`);
}

export function getUserCases(id, query) {
  const uri = new URI(`${process.env.REACT_APP_API_REST_ENDPOINT}/patient/${id}/cases`);
  if (query) uri.addSearch(query);
  return http.get(uri.toString());
}

export function cancelBulk(id, data) {
  return http.post(`${process.env.REACT_APP_API_REST_ENDPOINT}/patient/${id}/cases/services/cancel`, data);
}

export function convertBulkDrafts(id, data) {
  return http.post(`${process.env.REACT_APP_API_REST_ENDPOINT}/patient/${id}/cases/drafts/convert`, data);
}

export function getUserAvixo(query) {
  const uri = new URI(`${process.env.REACT_APP_API_REST_ENDPOINT}/user/avixo`);
  if (query) uri.addSearch(query);
  return http.get(uri.toString());
}

export function linkToAvixo(data) {
  return http.post(`${process.env.REACT_APP_API_REST_ENDPOINT}/user/avixo/link`, data);
}

export function unlinkFromAvixo(data) {
  return http.post(`${process.env.REACT_APP_API_REST_ENDPOINT}/user/avixo/unlink`, data);
}

export function attachRelative(id, data) {
  return http.put(`${process.env.REACT_APP_API_REST_ENDPOINT}/patient/${id}/relative`, data);
}

export function removeRelative(id, data) {
  return http.delete(`${process.env.REACT_APP_API_REST_ENDPOINT}/patient/${id}/relative`, { data });
}

export function getBusinesses(query) {
  const uri = new URI(`${process.env.REACT_APP_API_REST_ENDPOINT}/patient/businesses`);
  if (query) uri.addSearch(query);
  return http.get(uri.toString());
}
