import { useSnackBar } from "contexts/snackBarContext";
import { useEffect, useState } from "react";
import { getPrograms } from "services/programService";
import { store } from "stores/storeManager";

export default function usePrograms() {
  const { alpha2 } = store.useState(s => s.country);

  const [loading, setLoading] = useState(false);
  const [programs, setPrograms] = useState<Record<string, any>[]>([]);

  const snackBar = useSnackBar();

  useEffect(() => {
    let active = true;

    const fetchPrograms = async () => {
      setLoading(true);

      try {
        const { data } = await getPrograms({ country: alpha2 });
        if (!data.programs || !active) return;

        setPrograms(data.programs);
      } catch (error) {
        snackBar.pop({ content: error.data?.toString(), alertProps: { severity: "error" } });
      } finally {
        setLoading(false);
      }
    };

    fetchPrograms();

    return () => {
      active = false;
    };
  }, [alpha2, snackBar]);

  return { loading, programs };
}
