import { Description, Person } from "components/oldDesignAssets/icons";
import { useSnackBar } from "contexts/snackBarContext";
import { format, formatDistance } from "date-fns";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { confirmCancellation } from "../../../services/requestService";
import { requestStore } from "../../../stores/modalManager";
import { store } from "../../../stores/storeManager";
import { getFormattedAddress } from "../../../utils/globalUtils";
import Button from "../button";
import Checkbox from "../checkbox";
import Footer from "../footer";
import Header from "../header";
import { ArrowLeft } from "../icons";
import Input from "../input";
import UserVerificationID from "../userVerificationID";
import Request from "./request";

export default function ConfirmCancellation() {
  const snackBar = useSnackBar();
  const showRequest = () => {
    requestStore.update(s => {
      s.componentsToRender = <Request />;
    });
  };
  const { t = () => "" } = useTranslation();
  const { language } = store.useState(s => ({
    language: s.language,
  }));
  const request = requestStore.useState(s => ({ ...s.request, ...s.unsavedChanges }));
  const payer = request.owner || request.user;
  const serviceTitle = t(`${language}.serviceTitleShort`);
  const addressDetails = request.schedule.requestedAddress;
  const [cancellationContacted, setCancellationContacted] = useState(false);
  const [cancellationAction, setCancellationAction] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const hideModal = requestStore.useState(s => s.hideModal);

  const handleConfirmCancellation = async () => {
    try {
      setIsLoading(true);
      const result = await confirmCancellation(request._id, {
        confirmStatus: true,
        contacted: cancellationContacted,
        action: cancellationAction,
      });
      snackBar.pop({
        content: result.data.message,
      });
    } catch (error) {
      snackBar.pop({
        content: error.data?.toString(),
        alertProps: { severity: "error" },
      });
    } finally {
      setIsLoading(false);
      hideModal();
    }
  };
  return (
    <div className="modalContent confirmCancellationUM">
      <Header>
        <ArrowLeft onClick={showRequest} />
        <div className="title">Confirm Cancellation</div>
      </Header>
      <div className="content">
        <div className="serviceSection">
          {serviceTitle[request.type]}
          <span className="subtitle">{`S${request.id}`}</span>
        </div>
        <div className="scheduleSection">
          <div className="scheduleUM">
            {request.schedule.start ? format(new Date(request.schedule.start), "EEEE, dd MMM  · HH:mm") : "ASAP"}
          </div>
          <div className="addressUM">{getFormattedAddress(addressDetails)}</div>
        </div>

        <div className="userSection">
          <div className="user">
            <Person />
            <div className="userDetails">
              <UserVerificationID user={payer} />
            </div>
          </div>
        </div>
        <div className="reasonSection">
          Canceled by: <span className="redText">{request.cancelled.by?.name}</span>
          <br />
          Reason:
          <span className="redText">{` ${request.cancelled.reason} (${formatDistance(
            new Date(request.createdAt),
            new Date(request.cancelled.date),
          )} after creation)`}</span>
        </div>
        <hr />
        <div className="contactedSection">
          <Checkbox
            defaultChecked={cancellationContacted}
            text="User was able to be Contacted"
            onChange={check => setCancellationContacted(check)}
          />
        </div>
        <div className="actionSection">
          <Input
            className="actionInput"
            icon={<Description />}
            noBorder
            value={cancellationAction}
            placeholder="Action Taken by PCT (Mandatory; Same as Hubspot)"
            isMultiLine
            counter
            maxLength={1000}
            onChange={message => setCancellationAction(message)}
          />
        </div>
      </div>
      <Footer>
        <div className="footerContainer">
          <div className="actions alignRight">
            <div className="rightActions">
              <Button
                text="Close Request"
                type="button"
                className="primary"
                disabled={isLoading}
                onClick={handleConfirmCancellation}
              />
            </div>
          </div>
        </div>
      </Footer>
    </div>
  );
}
