import { Box, Button, CircularProgress, Divider, Grid, Typography } from "@mui/material";
import CustomChip from "components/global/CustomChip";
import { Patient, Thermometer } from "components/oldDesignAssets/icons";
import { useSnackBar } from "contexts/snackBarContext";
import usePrograms from "hooks/usePrograms";
import { useEffect, useRef, useState } from "react";
import escape from "regex-escape";
import { getPackages } from "services/ubfOldService";
import { caseTypes, packageRequiredCases, programRequiredCases } from "utils/globalConfig";
import { deepCloneObject } from "utils/globalUtils";
import { getCases } from "../../../services/caseService";
import { requestStore } from "../../../stores/modalManager";
import { store } from "../../../stores/storeManager";
import Header from "../header";
import { ArrowLeft } from "../icons";
import Input from "../input";
import CreateRequest from "./createRequest";
import EditRequest from "./editRequest";

export default function CaseList() {
  const country = store.useState(s => s.country);
  const request = requestStore.useState(s => ({ ...s.request, ...s.unsavedChanges }));

  const [filter, setFilter] = useState("");
  const [errors, setErrors] = useState({ program: false, business: false, package: false });

  const { programs } = usePrograms();
  const { loading: loadingExistingCases, existingCases } = useExistingCases(request.user._id);
  const { loading: loadingPackages, packages } = usePackages(request.case?.program?._id);

  const programContainerRef = useRef(null);
  const packageContainerRef = useRef(null);

  const isDelivery = request.type === "Delivery";
  const isProgramRequired =
    programRequiredCases?.[country.alpha2]?.includes(request.case.type) && !request.case.program;
  const isPackageRequired =
    packageRequiredCases?.[country.alpha2]?.includes(request.case.type) &&
    request.case.program &&
    !request.case.package;
  const caseId = request.case.id ? `C-${request.case.id}` : "(New)";

  useEffect(() => {
    if (!isProgramRequired || !programContainerRef.current) return;

    programContainerRef.current.scrollIntoView({ behavior: "smooth" });
  }, [isProgramRequired]);

  useEffect(() => {
    if (!isPackageRequired || loadingPackages || !packageContainerRef.current) return;

    packageContainerRef.current.scrollIntoView({ behavior: "smooth" });
  }, [isPackageRequired, loadingPackages]);

  const showRequest = e => {
    e.preventDefault();

    if (isProgramRequired) {
      setErrors({ ...errors, program: true });
      return;
    }

    if (isPackageRequired) {
      setErrors({ ...errors, package: true });
      return;
    }

    requestStore.update(s => {
      s.componentsToRender = request._id ? <EditRequest /> : <CreateRequest />;
    });
  };

  const onClickExistingCase = selectedCase => () => {
    const mappedCase = deepCloneObject(selectedCase);
    let defaultSpecialty;

    if (mappedCase.package?.ref?._id) {
      mappedCase.package = mappedCase.package.ref;
      defaultSpecialty = mappedCase.package.metadata?.specialty;
    }

    requestStore.update(s => {
      s.unsavedChanges.case = mappedCase;
      s.unsavedChanges.isDirty.case = true;

      const items = [...(s.request?.items ?? []), ...(s.unsavedChanges?.items ?? [])];
      s.unsavedChanges.items = items.filter(item => item.item.tags.includes("destination"));
      s.unsavedChanges.isDirty.items = true;

      if (!isDelivery && defaultSpecialty) s.unsavedChanges.specialty = defaultSpecialty;

      if (!mappedCase.business && s.unsavedChanges.specialty === "Rider") s.unsavedChanges.billing.free = true;
    });

    resetErrors();
  };

  const onClickCase = selectedCase => () => {
    requestStore.update(s => {
      s.unsavedChanges.case = selectedCase;
      s.unsavedChanges.isDirty.case = true;

      const items = [...(s.request?.items ?? []), ...(s.unsavedChanges?.items ?? [])];
      s.unsavedChanges.items = items.filter(item => item.item.tags.includes("destination"));
      s.unsavedChanges.isDirty.items = true;

      if (!selectedCase.business && s.unsavedChanges.specialty === "Rider") s.unsavedChanges.billing.free = true;
    });

    resetErrors();
  };

  const onClickPackage = selectedPackage => () => {
    const defaultSpecialty = selectedPackage.metadata?.specialty;

    requestStore.update(s => {
      s.unsavedChanges.case.package = selectedPackage;
      if (!isDelivery && defaultSpecialty) s.unsavedChanges.specialty = defaultSpecialty;
    });
  };

  const resetErrors = () => {
    setErrors({ business: false, program: false, package: false });
  };

  return (
    <div className="modalContent caseListUM">
      <Header>
        <ArrowLeft role="button" aria-label="backButton" onClick={showRequest} />

        <div className="title">Case</div>
      </Header>

      <div className="content">
        <Input value={filter} icon={<Thermometer />} onChange={setFilter} placeholder="Search Case" />

        <div className="selectedCaseContainer">
          {request.case.type ? (
            <CustomChip
              label={
                <>
                  {request.case.type} {request.case.program?.title} {request.case.package?.name}{" "}
                  {request.case.business?.tag} {caseId}
                </>
              }
              onClick={onClickCase({})}
              isSelected
            />
          ) : (
            "No case selected"
          )}
        </div>

        <div className="caseListContainer">
          <div className="title">
            <Patient />
            {request.user.name}&#39;s existing cases:
          </div>

          <div className="existingCaseContainer">
            {loadingExistingCases ? (
              <Grid textAlign="center">
                <CircularProgress size={24} />
              </Grid>
            ) : (
              existingCases
                .filter(c =>
                  new RegExp(escape(`${filter}`), "gi").test(
                    `${c.id} ${c.type} ${c.program?.title || ""} ${c.package?.name || ""}`,
                  ),
                )
                .filter(c => {
                  if (!request.business) return true;
                  return !c.business || c.business?.name === request.business?.name;
                })
                .map(c => {
                  const isSelected = c._id === request.case?._id;
                  const onClickParams = isSelected ? {} : c;

                  return (
                    <CustomChip
                      key={c.id}
                      label={
                        <>
                          {c.type} {c.program?.title} {c.package?.name} {c.business?.tag} C-{c.id}
                        </>
                      }
                      isSelected={isSelected}
                      onClick={onClickExistingCase(onClickParams)}
                      isExisting
                    />
                  );
                })
            )}
          </div>

          <div className="newCaseTitle">Segment Type*:</div>

          <div className="createCaseContainer">
            {caseTypes.map(type => {
              const isSelected = type === request.case?.type && !request.case._id;
              const onClickParams = isSelected ? {} : { type };

              return (
                <CustomChip key={type} label={type} isSelected={isSelected} onClick={onClickCase(onClickParams)} />
              );
            })}
          </div>

          {programs.find(p => request.case.type === p.type) && (
            <>
              <div className="newCaseTitle">
                <Typography fontSize={12} color={errors.program ? "errorRed.500" : "black"}>
                  Programme*:
                </Typography>
              </div>

              <div ref={programContainerRef} className="createCaseContainer">
                {programs
                  .filter(p => request.case.type === p.type)
                  .map(p => {
                    const { _id, title, condition } = p;
                    const isSelected = _id === request.case?.program?._id && !request.case._id;
                    const onClickParams = { type: request.case.type };

                    if (!isSelected) onClickParams.program = { _id, title, condition };

                    return (
                      <CustomChip
                        key={_id}
                        label={title}
                        isSelected={isSelected}
                        onClick={onClickCase(onClickParams)}
                      />
                    );
                  })}
              </div>
            </>
          )}

          {packageRequiredCases?.[country.alpha2]?.includes(request.case.type) && request.case.program && (
            <Grid ref={packageContainerRef} mx={5.5} mt={2}>
              <Grid color={errors.package ? "errorRed.500" : "primary"}>Package*:</Grid>

              <Grid mt={1}>
                {loadingPackages ? (
                  <Grid textAlign="center">
                    <CircularProgress size={24} />
                  </Grid>
                ) : (
                  packages.map(p => {
                    const isSelected = p._id === request.case.package?._id;

                    return (
                      <CustomChip key={p._id} label={p.name} isSelected={isSelected} onClick={onClickPackage(p)} />
                    );
                  })
                )}
              </Grid>
            </Grid>
          )}
        </div>
      </div>

      <Divider />

      <Box p={2} textAlign="right">
        <Button
          color="secondary"
          onClick={showRequest}
          data-testid="saveButton"
          sx={{
            height: "34px",
            fontSize: "14px",
            lineHeight: "18px",
            minHeight: "unset",
          }}
          variant="contained"
          disabled={!request?.case?.type || isProgramRequired || isPackageRequired}>
          Save
        </Button>
      </Box>
    </div>
  );
}

function useExistingCases(userRef) {
  const { alpha2 } = store.useState(s => s.country);

  const [loading, setLoading] = useState(false);
  const [existingCases, setExistingCases] = useState([]);

  const snackBar = useSnackBar();

  useEffect(() => {
    let active = true;

    const fetchCases = async () => {
      setLoading(true);

      try {
        const { data } = await getCases({
          userRef,
          discharged: false,
          cancelled: false,
          countryCode: alpha2,
          perPage: 100,
        });
        if (!data.cases || !active) return;

        setExistingCases(data.cases);
      } catch (error) {
        snackBar.pop({ content: error.data?.toString(), alertProps: { severity: "error" } });
      } finally {
        setLoading(false);
      }
    };

    fetchCases();

    return () => {
      active = false;
    };
  }, [alpha2, snackBar, userRef]);

  return { loading, existingCases };
}

function usePackages(programRef) {
  const [loading, setLoading] = useState(false);
  const [packages, setPackages] = useState([]);

  const snackBar = useSnackBar();

  useEffect(() => {
    if (!programRef) {
      setPackages([]);
      return;
    }

    let active = true;

    const fetchData = async () => {
      setLoading(true);

      try {
        const { data } = await getPackages({ origin: "program", ref: programRef });
        if (!data.packages || !active) return;

        setPackages(data.packages);
      } catch (error) {
        snackBar.pop({ content: error.data?.toString(), alertProps: { severity: "error" } });
      } finally {
        setLoading(false);
      }
    };

    fetchData();

    return () => {
      active = false;
    };
  }, [programRef, snackBar]);

  return { loading, packages };
}
