import CloseIcon from "@mui/icons-material/Close";
import { LoadingButton } from "@mui/lab";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import { createContext, useCallback, useContext, useMemo, useState } from "react";

interface OpenParams {
  title: string;
  description: string | React.ReactNode;
  onConfirm: () => Promise<void> | void;
  onCancel?: () => void;
  confirmationLabel?: string;
  cancelLabel?: string;
  isConfirmationDisabled?: boolean;
  isCancelDisabled?: boolean;
}

interface ConfirmationModalContextType {
  open: (config: OpenParams) => void;
}

export const ConfirmationModalContext = createContext<ConfirmationModalContextType>({
  open: () => {},
});

export function useConfirmationModal() {
  if (!ConfirmationModalContext) {
    throw new Error("useConfirmationModal must be used within a ConfirmationModalProvider");
  }

  return useContext(ConfirmationModalContext);
}

export default function ConfirmationModalProvider({ children }: Readonly<{ children: React.ReactNode }>) {
  const [modalConfigs, setModalConfigs] = useState<Partial<OpenParams>[]>([]);
  const [isConfirming, setIsConfirming] = useState(false);

  const onOpen = useCallback(
    ({
      title,
      description,
      onConfirm,
      onCancel,
      confirmationLabel,
      cancelLabel,
      isConfirmationDisabled,
      isCancelDisabled,
    }: OpenParams) => {
      setModalConfigs(prev => [
        ...prev,
        {
          title,
          description,
          onConfirm,
          onCancel,
          confirmationLabel: confirmationLabel ?? "Confirm",
          cancelLabel: cancelLabel ?? "Cancel",
          isConfirmationDisabled: isConfirmationDisabled || false,
          isCancelDisabled: isCancelDisabled || false,
        },
      ]);
    },
    [],
  );

  const handleClose = useCallback(
    (modalConfig: Partial<OpenParams>) => () => {
      setModalConfigs(prev => prev.filter(config => config.title !== modalConfig.title));
    },
    [],
  );

  const handleConfirm = useCallback(
    (modalConfig: Partial<OpenParams>) => async () => {
      if (typeof modalConfig.onConfirm === "function") {
        setIsConfirming(true);
        await modalConfig.onConfirm();
        setIsConfirming(false);
      }
      handleClose(modalConfig)();
    },
    [handleClose],
  );

  const contextValue = useMemo(() => ({ open: onOpen }), [onOpen]);

  return (
    <ConfirmationModalContext.Provider value={contextValue}>
      {children}

      {modalConfigs.length > 0 &&
        modalConfigs.map(modalConfig => (
          <Dialog
            key={modalConfig.title}
            open
            onClose={modalConfig?.onCancel ?? handleClose(modalConfig)}
            aria-labelledby="responsive-dialog-title"
            data-testid="confirmation-modal"
            sx={{
              "& .MuiDialog-paper": {
                width: "401px",
              },
            }}>
            <DialogTitle
              variant="body1"
              id="responsive-dialog-title"
              textTransform="unset"
              whiteSpace="break-spaces"
              fontWeight={700}>
              {modalConfig?.title || "Are you sure?"}
            </DialogTitle>
            <IconButton
              aria-label="close"
              onClick={modalConfig?.onCancel ?? handleClose(modalConfig)}
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
              }}>
              <CloseIcon />
            </IconButton>

            <DialogContent>
              {typeof modalConfig?.description === "string" ? (
                <DialogContentText variant="body2" color="text.secondary">
                  {modalConfig.description}
                </DialogContentText>
              ) : (
                modalConfig?.description
              )}
            </DialogContent>

            <DialogActions
              sx={{
                justifyContent: "space-between",
                mx: 1,
              }}>
              <Button
                onClick={modalConfig?.onCancel ?? handleClose(modalConfig)}
                color="error"
                disabled={modalConfig?.isCancelDisabled}>
                {modalConfig?.cancelLabel || "Cancel"}
              </Button>

              <LoadingButton
                autoFocus
                onClick={handleConfirm(modalConfig)}
                variant="contained"
                color="componentNewEmerald50"
                loading={isConfirming}
                disabled={modalConfig?.isConfirmationDisabled || isConfirming}
                data-testid="confirm-button"
                disableElevation>
                {modalConfig?.confirmationLabel || "Confirm"}
              </LoadingButton>
            </DialogActions>
          </Dialog>
        ))}
    </ConfirmationModalContext.Provider>
  );
}
