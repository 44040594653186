import LocationOnIcon from "@mui/icons-material/LocationOn";
import { Box, Button, Dialog, Divider, Grid, Typography } from "@mui/material";
import { useState } from "react";
import { useSnackBar } from "../../../contexts/snackBarContext";
import { addAddress, updateAddress } from "../../../services/userService";
import { requestStore } from "../../../stores/modalManager";
import { store } from "../../../stores/storeManager";
import { getFormattedAddress } from "../../../utils/globalUtils";
import GoogleMapAutocomplete from "../googleMapAutocomplete";
import Header from "../header";
import { ArrowLeft } from "../icons";
import Input from "../input";
import OneMapAutocomplete from "../oneMapAutocomplete";
import AddressList from "./addressList";

const form = {
  new: {
    title: "Add Address Details",
    submitButtonText: "Save",
    submitAction: (userRef, address) => {
      return addAddress(userRef, address);
    },
  },
  edit: {
    title: "Edit Address Details",
    submitButtonText: "Update",
    submitAction: (userRef, address) => {
      return updateAddress(userRef, address);
    },
  },
};

const generateEmptyAddress = (alpha2, id = undefined) => ({
  _id: id,
  line1: "",
  line2: "",
  city: "",
  unit: "",
  postCode: "",
  title: "",
  alpha2,
});

export default function AddressForm({ address }) {
  const request = requestStore.useState(s => ({ ...s.request, ...s.unsavedChanges }));
  const alpha2 = store.useState(s => s.country.alpha2);
  const [unsavedAddress, setUnsavedAddress] = useState({
    ...generateEmptyAddress(alpha2),
    ...address,
  });
  const [formError, setFormError] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  const [openConfirmDiscardDialog, setOpenConfirmDiscardDialog] = useState(false);

  const snackBar = useSnackBar();

  const formMode = address?._id ? "edit" : "new";

  const onClickBack = () => {
    if (hasUnsavedChanges) {
      setOpenConfirmDiscardDialog(true);
      return;
    }

    renderAddressList();
  };

  const renderAddressList = () => {
    requestStore.update(s => {
      s.componentsToRender = <AddressList />;
    });
  };

  const onClickCancelDiscard = () => {
    setOpenConfirmDiscardDialog(false);
  };

  const onChangeAddress = newAddress => {
    setUnsavedAddress({ ...generateEmptyAddress(alpha2, address?._id), ...newAddress });
    setHasUnsavedChanges(true);
  };

  const updateUnsavedAddress = fieldName => value => {
    setUnsavedAddress({ ...unsavedAddress, [fieldName]: value });
    setHasUnsavedChanges(true);
  };

  const validateForm = () => {
    const error = {};

    if (unsavedAddress.line1.length === 0) error.line1 = "Please search an address";
    if (unsavedAddress.title.length === 0) error.title = "Location name is required";

    setFormError(error);

    return Object.keys(error).length === 0;
  };

  const onClickSave = async () => {
    const userRef = request.owner?._id || request.user._id;

    if (!validateForm()) return;

    try {
      setIsLoading(true);

      const { data } = await form[formMode].submitAction(userRef, unsavedAddress);

      requestStore.update(s => {
        const patientFieldName = request.owner ? "owner" : "user";
        const patient = s.unsavedChanges[patientFieldName] || s.request[patientFieldName];
        patient.addresses = data.addresses;
      });

      snackBar.pop({ content: data.message });

      renderAddressList();
    } catch (error) {
      snackBar.pop({ content: error.data?.toString(), alertProps: { severity: "error", color: "error" } });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <Box className="modalContent addressFormUM">
        <Header>
          <ArrowLeft onClick={onClickBack} />
          <Box className="title">{form[formMode].title}</Box>
        </Header>

        <Box className="content">
          <Grid container gap={2} px={2} wrap="nowrap">
            <LocationOnIcon htmlColor="#979797" sx={{ mt: 1 }} />

            <Grid item xs container direction="column" gap={2}>
              <div>
                <GoogleMapAutocomplete
                  address={unsavedAddress}
                  placeholder="Search by Address"
                  data-testid="searchByAddress"
                  onChange={onChangeAddress}
                />
                {formError.line1 && (
                  <Typography component="div" variant="caption" color="red" pl={1}>
                    {formError.line1}
                  </Typography>
                )}
              </div>

              <Input value={getFormattedAddress({ line2: unsavedAddress.line2, city: unsavedAddress.city })} disabled />

              <Grid container gap={1} wrap="nowrap" className="unitPostCode">
                <Input
                  placeholder="Unit No."
                  data-testid="inputUnitNo"
                  value={unsavedAddress.unit}
                  onChange={updateUnsavedAddress("unit")}
                />

                {alpha2 === "SG" ? (
                  <OneMapAutocomplete
                    placeholder="Search by Postal Code"
                    address={unsavedAddress}
                    onChange={onChangeAddress}
                  />
                ) : (
                  <Input
                    placeholder="Postcode"
                    value={unsavedAddress.postCode}
                    onChange={updateUnsavedAddress("postCode")}
                  />
                )}
              </Grid>

              <div>
                <Input
                  placeholder="Name This Location"
                  data-testid="inputLocationName"
                  value={unsavedAddress.title}
                  onChange={updateUnsavedAddress("title")}
                />
                {formError.title && (
                  <Typography component="div" variant="caption" color="red" pl={1}>
                    {formError.title}
                  </Typography>
                )}
              </div>
            </Grid>
          </Grid>
        </Box>

        <Divider />

        <Box p={2} textAlign="right">
          <Button
            className="btnSave"
            data-testid="saveButton"
            onClick={onClickSave}
            variant="contained"
            disabled={isLoading}
            disableElevation>
            {form[formMode].submitButtonText}
          </Button>
        </Box>
      </Box>

      {openConfirmDiscardDialog && (
        <Dialog open>
          <Box p={2}>
            <Box color="#56738F">
              <Typography component="div" fontSize="18px" fontWeight={600}>
                Confirm Back
              </Typography>

              <Typography component="div" mt={2}>
                There are unsaved changes.
              </Typography>

              <Typography component="div" mt={2}>
                Are you sure you want to go back?
              </Typography>
            </Box>

            <Grid container justifyContent="space-between" mt={3}>
              <Button className="btnCancel" onClick={onClickCancelDiscard} sx={{ color: "#e4104f" }} disableElevation>
                Cancel
              </Button>

              <Button
                className="btnConfirm"
                onClick={renderAddressList}
                variant="contained"
                sx={{
                  background: "#00c3b5",
                  color: "white",

                  "&:hover": {
                    background: "#00c3b5",
                    color: "white",
                  },
                }}
                disableElevation>
                Confirm
              </Button>
            </Grid>
          </Box>
        </Dialog>
      )}
    </>
  );
}
