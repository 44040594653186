import LocationCityIcon from "@mui/icons-material/LocationCity";
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Divider,
  List,
  ListItem,
  ListItemText,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSnackBar } from "../../../contexts/snackBarContext";
import { getPlaces } from "../../../services/placeService";
import { requestStore } from "../../../stores/modalManager";
import { store } from "../../../stores/storeManager";
import Header from "../header";
import { ArrowLeft } from "../icons";
import CreateRequest from "./createRequest";
import EditRequest from "./editRequest";

export default function CLinicList() {
  const { alpha2, language } = store.useState(s => ({ alpha2: s.country.alpha2, language: s.language }));
  const request = requestStore.useState(s => ({ ...s.request, ...s.unsavedChanges }));
  const [isLoading, setIsLoading] = useState(false);
  const [clinics, setClinics] = useState([]);
  const [clinicsCount, setClinicsCount] = useState(0);
  const [nextPage, setNextPage] = useState(1);
  const [search, setSearch] = useState(request.schedule?.requestedAddress?.title || "");

  const { t } = useTranslation();

  const placeTypeObject = t(`${language}.placeType`);
  const placeTypeArray = Object.keys(placeTypeObject);
  const [selectedTypes, setSelectedTypes] = useState(placeTypeArray);
  const [isAllType, setIsAllType] = useState(true);

  const snackBar = useSnackBar();
  const timeout = useRef();

  const onClickBack = () => {
    requestStore.update(s => {
      s.componentsToRender = request._id ? <EditRequest /> : <CreateRequest />;
    });
  };

  const debounceFetch = useCallback(
    (page, next) => {
      const currPage = page || 1;
      clearTimeout(timeout.current);
      timeout.current = setTimeout(async () => {
        try {
          setIsLoading(true);
          const result = await getPlaces({
            alpha2,
            perPage: 10,
            page: currPage,
            search,
            type: selectedTypes.filter(type => type !== "all"),
          });
          if (!result.data?.ok) throw new Error("Failed to fetch Clinic places");

          const { places, pagination } = result.data;
          setClinics(v => (next ? v.concat(places) : places));
          setClinicsCount(pagination.count);

          if (places.length) setNextPage(pagination.page + 1);
        } catch (error) {
          snackBar.pop({ content: error.data?.toString(), alertProps: { severity: "error", color: "error" } });
        } finally {
          setIsLoading(false);
        }
      }, 400);
    },
    [alpha2, search, selectedTypes, snackBar],
  );

  useEffect(() => {
    debounceFetch();
  }, [debounceFetch]);

  const renderSelectedTypes = selected =>
    selected.length === placeTypeArray.length
      ? "All Places"
      : `${selected.length} Place${selected.length > 1 ? "s" : ""}`;

  const onScroll = event => {
    const listboxNode = event.currentTarget;
    if (listboxNode.scrollTop + listboxNode.clientHeight >= listboxNode.scrollHeight) {
      debounceFetch(nextPage, true);
    }
  };

  const onChangeSearch = e => {
    const { value } = e.target;
    setSearch(value);
  };

  const onChangeFilterType = (e, child) => {
    const { value } = e.target;
    if (child.props.value === "all" || !value.length) return;

    setSelectedTypes(value);
    const hasAll = value.filter(v => v !== "all").length === placeTypeArray.length;
    setIsAllType(hasAll);
  };

  const onChangeAllType = () => {
    const toggledVal = !isAllType;
    setIsAllType(toggledVal);

    if (toggledVal) setSelectedTypes(placeTypeArray);
    else setSelectedTypes(placeTypeArray.filter(v => v === "hospital"));
  };

  const setClinicPlace = clinic => () => {
    requestStore.update(s => {
      s.unsavedChanges.placeRef = clinic._id;
      s.unsavedChanges.schedule.requestedAddress = { ...clinic.address, title: clinic.title || clinic.name };
      s.unsavedChanges.isDirty.address = true;
    });

    onClickBack();
  };

  return (
    <Box className="modalContent addressListUM clinicPlaces">
      <Header>
        <ArrowLeft onClick={onClickBack} />
        <Box className="title">Choose Clinic</Box>
      </Header>

      <Box className="search">
        <LocationCityIcon htmlColor="#979797" sx={{ mr: 1 }} />
        <TextField value={search} fullWidth size="small" placeholder="Search Clinics" onChange={onChangeSearch} />
        <Select
          size="small"
          multiple
          value={selectedTypes}
          renderValue={renderSelectedTypes}
          onChange={onChangeFilterType}>
          <MenuItem onClick={onChangeAllType} divider dense value="all">
            <Checkbox checked={isAllType} />
            <ListItemText primary={<Box sx={{ fontSize: "12px" }}>All Places</Box>} />
          </MenuItem>
          {placeTypeArray.map((option, i) => (
            <MenuItem dense key={i} value={option}>
              <Checkbox checked={selectedTypes.indexOf(option) > -1} />
              <ListItemText primary={<Box sx={{ fontSize: "12px" }}>{placeTypeObject[option]}</Box>} />
            </MenuItem>
          ))}
        </Select>
      </Box>

      <Divider />

      <ListItem
        className="item header"
        secondaryAction={<Button sx={{ fontSize: "12px", color: "#126EF8", marginRight: "-8px" }}>New Place</Button>}>
        <ListItemText primary={`Available Clinics (${clinics.length} of ${clinicsCount}):`} />
      </ListItem>

      <List className="list" onScroll={onScroll}>
        {clinics.map(clinic => (
          <ListItem
            onClick={setClinicPlace(clinic)}
            className="item"
            button
            key={clinic._id}
            secondaryAction={placeTypeObject[clinic.type] || clinic.type}>
            <ListItemText primary={clinic.name} secondary={clinic.address?.oneLine} />
          </ListItem>
        ))}
      </List>

      {isLoading && (
        <Box className="loading">
          <CircularProgress />
        </Box>
      )}
    </Box>
  );
}
