import { Link } from "@mui/material";
import { useConfirmationModal } from "contexts/confirmationModalContext";
import { useSnackBar } from "contexts/snackBarContext";
import { format, formatDistanceToNow } from "date-fns";
import { store } from "stores/storeManager";
import { createPasswordToken, sendAppLinkSMS } from "../../../services/userService";
import Checkbox from "../checkbox";
import { Chain, SpeedocIcon } from "../icons";

export default function AppLinks({ defaultChecked, onClickCheckBox, user }) {
  const snackBar = useSnackBar();
  const { open } = useConfirmationModal();
  const { alpha2 } = store.useState(s => ({
    alpha2: s.country.alpha2,
  }));

  const sendAppLinkHandler = async () => {
    try {
      const { data } = await sendAppLinkSMS(user._id, alpha2);
      snackBar.pop({
        content: data.message,
      });
    } catch (error) {
      snackBar.pop({
        content: error.data?.toString(),
        alertProps: { severity: "error" },
      });
    }
  };
  const sendResetPasswordHandler = async () => {
    try {
      const { data } = await createPasswordToken(user._id);
      snackBar.pop({
        content: data.message,
      });
    } catch (error) {
      snackBar.pop({
        content: error.data?.toString(),
        alertProps: { severity: "error" },
      });
    }
  };
  const onClickSendAppLinkSMS = () => {
    const phoneNumber = user.phoneNumbers[0];
    open({
      title: "Confirm Send Notification",
      description: `User will be sent a download app link via SMS to ${phoneNumber.ext}${phoneNumber.number}.`,
      onConfirm: sendAppLinkHandler,
    });
  };
  const onClickSendResetPassword = () => {
    open({
      title: "Confirm Send Notification",
      description: `User will be sent a reset password via email to ${user.email.address}.`,
      onConfirm: sendResetPasswordHandler,
    });
  };

  const CopyLink = (
    <Link
      color="#126EF8"
      sx={{ cursor: "pointer" }}
      className="copyLink"
      underline="none"
      onClick={() => {
        navigator.clipboard.writeText("https://bit.ly/appsmslogin");
      }}>
      <Chain />
      Copy Link
    </Link>
  );

  return (
    <>
      <div className="verifiedUserInfo">
        <div className="info">
          <div className="icon">
            <SpeedocIcon />
          </div>
          <div className="text">
            {user?.isAppUser ? (
              <div className="title">Verified App User</div>
            ) : user?._id ? (
              <div className="title">Non App User</div>
            ) : (
              <div className="title">New User</div>
            )}
            {user?._id && (
              <div className="subtitle">
                Created: {format(new Date(user.createdAt), "dd MMM yyyy")} ·
                {user?.isAppUser
                  ? ` Active ${
                      user.lastActive ? formatDistanceToNow(new Date(user.lastActive), { addSuffix: true }) : "N/A"
                    }`
                  : " Not Registered"}
              </div>
            )}
          </div>
        </div>
        {!user?._id && (
          <>
            <div className="checkBoxInput">
              <Checkbox defaultChecked={defaultChecked} onChange={onClickCheckBox} text="Send Download App SMS" />
            </div>
          </>
        )}
      </div>
      {user?._id ? (
        <>
          <Link color="#126EF8" sx={{ cursor: "pointer" }} className="sendSMSLink" onClick={onClickSendAppLinkSMS}>
            Send Download App SMS
          </Link>
          {CopyLink}
          <Link
            color="#126EF8"
            sx={{ cursor: "pointer" }}
            className="sendResetPasswordLink"
            onClick={onClickSendResetPassword}>
            Send Reset Password Email
          </Link>
        </>
      ) : (
        CopyLink
      )}
    </>
  );
}
